<div class="container mt-2">
    <!-- <div class="header">
      <div class="logo_full"><img src="./assets/logo_full.png" alt="Fitprenda"></div>
    </div> -->
    <app-instore-header [IncludeNavigate]="false"></app-instore-header>    


    <div class="tuto">
      <div class="row justify-content-center">
        <div class="col text-center">
            <div class="title-fitprenda mt-1 fitprenda-text-no-transform">Conocé tu talle</div>
            <div class="subtitle-fitprenda fitprenda-text-no-transform">En solo 3 pasos</div>
        </div>
    </div>
    
      <div class="row">
        <div class="col-4">
          <div class="icon_tuto"></div>
        </div>
        <div class="col-8">
          <div class="row mt-4 mb-4">
            <span class="num">1.&nbsp;</span>
            <span class="tex fitprenda-text-no-transform">Escaneá la etiqueta</span>
            <!-- <div class="num col-3">1</div>
            <div class="tex col-9">Escaneá<br><span>la etiqueta</span></div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="icon_tuto"></div>
        </div>        
        <div class="col-8">
          <div class="row mt-4 mb-4">
            <span class="num">2.&nbsp;</span>
            <span class="tex fitprenda-text-no-transform">Ingresá tus datos</span>
            <!-- <div class="tex col-9 text-right pr-0">Ingresá<br><span>tus datos</span></div>
            <div class="num col-3">2</div> -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="icon_tuto"></div>
        </div>
        <div class="col-8">
          <div class="row mt-4 mb-4">
            <span class="num">3.&nbsp;</span>
            <span class="tex fitprenda-text-no-transform">Listo!&nbsp;</span>
            <span class="tex-sub fitprenda-text-no-transform">Conocé tu talle sugerido para la prenda</span>            
            <!-- <div class="num col-3">3</div>
            <div class="tex col-9">tu talle<br><span>sugerido</span></div> -->
          </div>
        </div>
      </div>

    </div>

    <div class="btn btn-fitprenda-jch btn-block col-8 offset-2" (click)="onClickButton();">Comenzar</div>

</div>
