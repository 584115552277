import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FitprendaService } from '../service-fitprenda/fitprenda/fitprenda.service';
import { FitprendaAuthenticate } from '../models/response-fitprenda/fitprenda-authenticate';
import { CookieCredService } from '../service-fitprenda/cookies/cookie-cred.service';
import { FitprendaResponse } from '../models/response-fitprenda/fitprenda-response';
import adapter from 'webrtc-adapter';
import Quagga from 'quagga';
import { FitprendaCustomerSettings } from '../models/response-fitprenda/fitprenda-customer-settings';


@Component({
  selector: 'app-instore-barcode',
  templateUrl: './instore-barcode.component.html',
  styleUrls: ['./instore-barcode.component.css']
})
export class InstoreBarcodeComponent implements OnInit {
  public startedScan: boolean = false;
  public actionScan: string = 'Escanear';
  private skuCode: string;
  private prevSkuCode: string;
  private key: string;

  private codePattern: RegExp;
  private codeLength: number;
  private codeType: string;

  private configQuagga: any;

  constructor(private actRoute: ActivatedRoute
            , private router: Router
            , private oCookieCred: CookieCredService
            , private fitprendaService: FitprendaService
            , private ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.key = this.actRoute.snapshot.params.hash;
    this.actionScan = 'Escanear';
    this.GetSettingsEmpresa();
  }

  public gotoProduct(): void{
    try {
      if (!(this.startedScan)) {
        this.startScanner();
      } else {
        this.stopScanner();
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public gotoProductNotfound(): void{
    this.router.navigate(['instore/noencontrado']);
  }

  public onInstoreDetails(): void {
    try {
      this.router.navigate(['instore/details']);
    } catch (ex) {
      console.log(ex);
    }
  }
  // public onClickButton(): void {
  //   try {
  //     // console.log(this.skuCode);

  //     this.AuthenticateFitPrenda();

  //   } catch (ex) {
  //     console.log(ex);
  //   }
  // }

  private startScanner() {
    this.ref.detectChanges();

    this.prevSkuCode = '';

    this.SettingConfigQuagga();

    Quagga.onProcessed((result) => this.onProcessed(result));

    Quagga.onDetected((result) => this.logCode(result));

    Quagga.offProcessed((result) => {
      // console.log('offProcessed');
      // console.log(result);
    });

    Quagga.offDetected((result) => {
      // console.log('offProcessed');
      // console.log(result);
    });

    Quagga.init(this.configQuagga, (err) => {
      if (err) {
        return console.log(err);
      }

      this.startedScan = true;
      this.actionScan = 'Detener cámara';

      Quagga.start();
    });

  }

  private stopScanner() {
    try {
      Quagga.stop();

      this.actionScan = 'Escanear';
      this.startedScan = false;
    } catch (ex) {
      console.log(ex);
    }
  }

  private onProcessed(result: any) {
    // const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    drawingCanvas.style.display = 'none';
    // if (result) {
    //   if (result.boxes) {
    //     drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width'), 10), parseInt(drawingCanvas.getAttribute('height'), 10));
    //     result.boxes.filter((box) => {
    //       return box !== result.box;
    //     }).forEach((box) => {
    //       Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: 'green', lineWidth: 2 });
    //     });
    //   }

    //   if (result.box) {
    //     Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: '#00F', lineWidth: 2 });
    //   }

    //   if (result.codeResult && result.codeResult.code) {
    //     Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
    //   }
    // }
  }

  private logCode(result) {
    const code: string = result.codeResult.code;

    try {
      if (this.startedScan) {
        if ((code !== undefined) && (code !== null) && (code !== this.prevSkuCode)) {

          // console.log('codigo', code);

          this.prevSkuCode = code;

          if (code.toString().includes('.')) {
            this.skuCode = code.split('.')[0];
          } else {
            this.skuCode = code;
          }

          this.ref.detectChanges();

          if ((this.skuCode.length === this.codeLength) && (this.codePattern.test(this.skuCode))) {
            this.stopScanner();

            this.AuthenticateFitPrenda();
          }
        }
      }

    } catch (ex) {
      console.log(ex);
    }
  }

  private AuthenticateFitPrenda(): void {
    try {

      this.fitprendaService.postAuthenticate(this.key, this.skuCode.toUpperCase()).subscribe((result: FitprendaResponse<FitprendaAuthenticate>) => {
        if (result.ResponseOK) {
          if (result.ResponseObject.EmpresaID > 0) {
            this.oCookieCred.setToken(this.key);
            this.oCookieCred.setEmpresa(result.ResponseObject.EmpresaID);
            this.oCookieCred.setEmpresaNombre(result.ResponseObject.Empresa);
            this.oCookieCred.setPlataforma(result.ResponseObject.PlatformID);
            this.oCookieCred.setSKU(this.skuCode.toUpperCase());
            this.oCookieCred.setDescripcionSKU(result.ResponseObject.DescripcionSKU);
            this.oCookieCred.setImagenSKU(result.ResponseObject.UrlImagenSKU);
            this.oCookieCred.setGenero(result.ResponseObject.GeneroSKU.toUpperCase());
            this.oCookieCred.setPosicion(result.ResponseObject.PosicionSKU);

            if (result.ResponseObject.GeneroSKU.toUpperCase() === 'MASCULINO') {
              this.router.navigate(['instore/producto']);
            } else if (result.ResponseObject.GeneroSKU.toUpperCase() === 'FEMENINO') {
              this.router.navigate(['instore/producto']);
            } else {
              // alert('CODIGO DESCONOCIDO');
              this.router.navigate(['instore/noencontrado']);
            }
          } else {
            this.router.navigate(['instore/noencontrado']);
            // alert('EMPRESA DESCONOCIDA');
          }
        } else {
          // alert(result.ResponseMessage);
          console.log(result.ResponseMessage);
          this.router.navigate(['instore/noencontrado']);
        }

      }, (err: any) => {
        this.prevSkuCode = '';
      }, () => {
        this.prevSkuCode = '';
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  private GetSettingsEmpresa(): void {
    try {
      this.fitprendaService.postSettings(this.key, '').subscribe((result: FitprendaResponse<FitprendaCustomerSettings>) => {
        if (result.ResponseOK) {
          if (result.ResponseObject.EmpresaID > 0) {
            this.codePattern = new RegExp(result.ResponseObject.PatternBarCode, 'g');
            this.codeLength = result.ResponseObject.LengthBarCode;
            this.codeType = result.ResponseObject.TypeBarCode;
          } else {
            this.codePattern = new RegExp('*', 'g');
            this.codeLength = 0;
            this.codeType = 'code_128_reader';
          }
        } else {
          this.codePattern = new RegExp('*', 'g');
          this.codeLength = 0;
          this.codeType = 'code_128_reader';
        }

      }, (err: any) => {
        this.codePattern = new RegExp('*', 'g');
        this.codeLength = 0;
        this.codeType = 'code_128_reader';
      }, () => {
        // this.prevSkuCode = '';
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  private SettingConfigQuagga(): void {
    try {
      this.configQuagga = {
        inputStream : {
          name : 'Live',
          type : 'LiveStream',
          target: document.querySelector('#inputBarcode'),    // Or '#yourElement' (optional)
          area: { // defines rectangle of the detection/localization area
            top: '0%',    // top offset
            right: '0%',  // right offset
            left: '0%',   // left offset
            bottom: '0%'  // bottom offset
          },
          constraints: {
            width: window.innerWidth,
            height: window.innerHeight - 40,
            focusMode: 'continuous',
            exposureMode: 'continuous',
            facingMode: 'environment'
          },
          singleChannel: false,
          size: 800,
        },
        locator: {
          halfSample: true,
          patchSize: 'medium', // x-small, small, medium, large, x-large
        },
        numOfWorkers: window.navigator.hardwareConcurrency || 4,
        frequency: 10,
        decoder : {
          readers : [
            this.codeType
            // 'code_128_reader'
            // , 'code_39_reader'
            // , 'ean_reader'
            // , 'ean_8_reader'
          ],
          multiple: false
        },
        locate: true
      };
    } catch (ex) {
      console.log(ex);
    }
  }
}
