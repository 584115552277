<div class="container">
	<app-instore-header [IncludeNavigate]="false"></app-instore-header>


	<div class="container-fitprenda">
		<div class="row justify-content-center">
			<div class="col text-center">
				<div class="title-fitprenda fitprenda-text-no-transform"><small><b>Talles consultados</b></small></div>
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col mt-4 px-5">
				<table class="table">
					<tbody>
						<tr *ngFor="let item of Items; let i = index">
							<td class="icon_tag"></td>
							<td class="product_name">{{item.Descripcion}} <br><span>[ {{item.SKU}} ]</span></td>
							<td class="text-right icon_size">{{item.Talle}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>


	<div class="btn btn-fitprenda-jch btn-block col-8 offset-2 mt-3" (click)="onOtherScan();">Escanear Otro</div>

</div>
