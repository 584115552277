<div class="header">
    <div class="row">
        <div class="col-4"[ngClass]="{'d-none': !IncludeNavigate || !IncludeNavigateOther }">
            <a class="btn-nav btn-nav-left" (click)="onClickOther();">escanear otro</a>
        </div>
        <div class="col">
            <div class="logo_full" [ngClass]="{'ml-4 invisible': !IncludeNavigateSize, 'mr-3 invisible': !IncludeNavigateOther }"><img src="./assets/logo.svg" alt="Jamin Chebar"></div>
        </div>
        <div class="col-5" [ngClass]="{'d-none': !IncludeNavigate  || !IncludeNavigateSize }">
            <a class="btn-nav btn-nav-right" (click)="onClickDetails();">talles consultados</a>
        </div>        
    </div>
  </div>
