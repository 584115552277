<div class="container mt-2">
    <div class="header">
      <div class="logo_full"><img src="./assets/logo.svg" alt="Jazmin Chebar"></div>
    </div>
    <div class="row justify-content-center">
        <div class="col text-center">
            <div class="title-fitprenda">Seleccioná la tienda</div>
            <div class="subtitle-fitprenda">Donde te encontrás</div>
        </div>
    </div>

    <div class="row justify-content-center">
        <div class="col">
            <input type="text" class="form-control buscar" placeholder="Buscar tienda">
        </div>
    </div>

    <div class="btn btn-block btn-tienda" 
        [ngClass]="{'btn-dark': ((i % 2) === 0), 'btn-secondary': ((i % 2) !== 0)}" 
        *ngFor="let item of listEmpresas; let i = index;"
        (click)="onClickButton(item.HashEmpresa);">
            {{item.Nombre}}
    </div>
    <!-- <div class="btn btn-block btn-fitprenda-primary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Falabella</div>
    <div class="btn btn-block btn-fitprenda-secondary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Jazmin Chebar</div>
    <div class="btn btn-block btn-fitprenda-primary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Rapsodia</div>
    <div class="btn btn-block btn-fitprenda-secondary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Ventanita</div>
    <div class="btn btn-block btn-fitprenda-primary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Falabella</div>
    <div class="btn btn-block btn-fitprenda-secondary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Jazmin Chebar</div>
    <div class="btn btn-block btn-fitprenda-primary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Rapsodia</div>
    <div class="btn btn-block btn-fitprenda-secondary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Ventanita</div>
    <div class="btn btn-block btn-fitprenda-primary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Falabella</div>
    <div class="btn btn-block btn-fitprenda-secondary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Jazmin Chebar</div>
    <div class="btn btn-block btn-fitprenda-primary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Rapsodia</div>
    <div class="btn btn-block btn-fitprenda-secondary btn-tienda" (click)="onClickButton('bG9jYWxob3N0');">Ventanita</div> -->
    
</div>

