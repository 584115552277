import { Component, OnInit } from '@angular/core';
import { CookieUserService } from '../service-fitprenda/cookies/cookie-user.service';
import { Router } from '@angular/router';
import { CookieCredService } from '../service-fitprenda/cookies/cookie-cred.service';
import { FitprendaService } from '../service-fitprenda/fitprenda/fitprenda.service';
import Stepper from 'bs-stepper';
import { FitprendaResponse } from '../models/response-fitprenda/fitprenda-response';
import { FitprendaCalculate } from '../models/response-fitprenda/fitprenda-calculate';

declare var jQuery: any;

@Component({
  selector: 'app-producto-male',
  templateUrl: './producto-male.component.html',
  styleUrls: ['./producto-male.component.css']
})
export class ProductoMaleComponent implements OnInit {
  public showCadera: boolean | false;
  public Altura: number | undefined;
  public Busto: number | undefined;
  public Cadera: number | undefined;
  public Peso: number | undefined;
  public Edad: number | undefined;
  public Talle: string | undefined;
  public mensajeSinTalle: string = 'Este producto no está disponible en el talle indicado según tus preferencias';
  private Prediccion: number | undefined;
  private Step: number | number;
  private oStepper: Stepper | undefined;
  private currentBiotipo: number;
  private oBiotipos: any[] = [{ name: 'ECTOMORFO',  description: 'Tus hombros, pecho y cadera son del mismo tamaño, sin una cintura definida.' }
                          , { name: 'MESOMORFO', description: 'Tus hombros y cadera son del mismo ancho, con la cintura muy pronunciada.' }
                          , { name: 'ENDOMORFO', description: 'Tu abdomen puede ser superior o estar en la misma línea de tus hombros y cadera.' }];

  constructor(private router: Router
            , private oCookieUser: CookieUserService
            , private oCookieCred: CookieCredService
            , private fitprendaService: FitprendaService) { }

  ngOnInit(): void {
    this.showCadera = (this.oCookieCred.getPosicion().toString() === '2');

    this.oStepper = new Stepper(document.querySelector('#stepper1'), {
      linear: true,
      animation: true
    });

    if ((this.oCookieUser.LastGenero() === this.oCookieCred.getGenero()) && (this.oCookieUser.LastPosicion() === this.oCookieCred.getPosicion())) {
      this.currentBiotipo = this.oCookieUser.getBiotipo();
      this.Altura = this.oCookieUser.getAltura();
      this.Busto = this.oCookieUser.getBusto();
      this.Cadera = this.oCookieUser.getCadera();
      this.Peso = this.oCookieUser.getPeso();
      this.Edad = this.oCookieUser.getEdad();
      this.Step = 3;
      this.oStepper.to(this.Step);
      this.calcuateSize(true);

    } else {
      this.Step = 1;
      this.currentBiotipo = this.oCookieUser.getBiotipo() || 0;
      this.Altura = this.oCookieUser.getAltura() || 150;
      this.Busto = this.oCookieUser.getBusto() || 90;
      this.Cadera = this.oCookieUser.getCadera() || 90;
      this.Peso = this.oCookieUser.getPeso() || 50;
      this.Edad = this.oCookieUser.getEdad() || 25;
    }
    this.setSliderPopOver();
  }

  public next(): void {
    try {
      if ((this.Step + 1) === 3) {
        this.oCookieUser.setBiotipo(this.currentBiotipo);
        this.oCookieUser.setAltura(this.Altura);
        this.oCookieUser.setBusto(this.Busto);
        this.oCookieUser.setCadera(this.Cadera);
        this.oCookieUser.setPeso(this.Peso);
        this.oCookieUser.setEdad(this.Edad);

        this.calcuateSize();
      } else {
        this.oStepper.next();
        this.Step++;
      }
    } catch (ex) {
      console.log(ex);
    }

  }

  public previous(): void {

    try  {
      this.oStepper.previous();
      this.Step--;
    } catch (ex) {
      console.log(ex);
    }

  }

  public descriptionBiotipo(): string {
    return this.oBiotipos[this.currentBiotipo].description;
  }

  public nextBiotipo(): void {
    try {
      if ((this.currentBiotipo + 1) === this.oBiotipos.length) {
        this.currentBiotipo = 0;
      } else {
        this.currentBiotipo++;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public previousBiotipo(): void {
    try {
      if ((this.currentBiotipo - 1) < 0) {
        this.currentBiotipo = (this.oBiotipos.length - 1);
      } else {
        this.currentBiotipo--;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public onOtherScan(): void {
    try {
      this.router.navigate(['barcode/' + this.oCookieCred.getToken()]);
    } catch (ex) {
      console.log(ex);
    }
  }

  public onInstoreDetails(): void {
    try {
      this.router.navigate(['instore/details']);
    } catch (ex) {
      console.log(ex);
    }
  }

  private calcuateSize(lastStep: boolean | undefined = false) {
    try {
      this.fitprendaService.postCalculate(this.oCookieCred.getSKU()
        , this.oCookieCred.getEmpresa()
        , this.oCookieCred.getGenero()
        , this.oBiotipos[this.oCookieUser.getBiotipo()].name
        , this.oCookieUser.getAltura()
        , this.oCookieUser.getPeso()
        , this.oCookieUser.getBusto()
        , this.oCookieUser.getCadera()
        , this.oCookieUser.getEdad()
        , this.oCookieCred.getPlataform()).subscribe((result: FitprendaResponse<FitprendaCalculate>) => {
          // console.log(result);
          if (result.ResponseOK) {

            this.Prediccion = result.ResponseObject.PrediccionID;
            this.Talle = result.ResponseObject.PrediccionTalle;

            this.oCookieUser.AddElement(this.oCookieCred.getSKU(), this.oCookieCred.getDescripcionSKU(), this.oCookieCred.getGenero(), this.oCookieCred.getPosicion(), this.Talle, this.Prediccion);

            if (!(lastStep)) {
              this.oStepper.next();
              this.Step++;
            }
          }
        });
    } catch (ex) {
      console.log(ex);
    }
  }

  private setSliderPopOver(): void {

    try {
      document.querySelectorAll('.container-range-fitprenda').forEach((element: any) => {

        const jqueryThis = jQuery(element);
        const jqueryInput = jQuery('input', jqueryThis);
        const jqueryTitle = jQuery('.setdata', jqueryThis);
        const thumbwidth = 20;

        jqueryInput.on('input change', (e: Event) => {
          const jqueryCurrentTarget = jQuery(e.currentTarget);
          // tslint:disable-next-line:quotemark
          // tslint:disable-next-line:radix
          let valor = parseInt(jqueryCurrentTarget.val());

          if ((valor === undefined) || (valor == null)) {
            valor = 0;
          }

          jqueryTitle.text(valor);

          const posicion = (valor - jqueryInput.attr('min')) / (jqueryInput.attr('max') - jqueryInput.attr('min'));
          const thumbCorrect = (thumbwidth * (posicion - 0.5)) * -1;
          const titlepos = Math.round((posicion * jqueryInput.width()) - thumbwidth / 4 + thumbCorrect);

          // tslint:disable-next-line:object-literal-key-quotes
          jqueryTitle.css({'left': titlepos});

        }).on('focus', (e: Event) => {
          if (isNaN(jQuery(e.currentTarget).val())) {
            jQuery(e.currentTarget).val(0);
          }
        }).on('touchstart', (e: Event) => {
          jqueryTitle.addClass('show-xs-fitprenda');
        }).on('touchend', (e: Event) => {
          jqueryTitle.removeClass('show-xs-fitprenda');
        });

        jQuery(window).on('resize', () => {
          jqueryInput.trigger('change');
        });
      });
    } catch (ex) { }
  }

}
