import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieUserService } from '../service-fitprenda/cookies/cookie-user.service';
import { CookieCredService } from '../service-fitprenda/cookies/cookie-cred.service';
import { CookieUserItem } from '../models/cookie/cookie-user';

@Component({
  selector: 'app-instore',
  templateUrl: './instore.component.html',
  styleUrls: ['./instore.component.css']
})
export class InstoreComponent implements OnInit {
  public Items: CookieUserItem[] | [];

  constructor(private router: Router
            , private oCookieUser: CookieUserService
            , private oCookieCred: CookieCredService) { }

  ngOnInit() {
    this.Items = this.oCookieUser.getItems();
  }

  public onOtherScan(): void {
    try {
      this.router.navigate(['barcode/' + this.oCookieCred.getToken()]);
    } catch (ex) {
      console.log(ex);
    }
  }
}
