import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FitprendaService } from '../service-fitprenda/fitprenda/fitprenda.service';
import { FitprendaEmpresa } from '../models/response-fitprenda/fitprenda-empresa';
import { FitprendaResponse } from '../models/response-fitprenda/fitprenda-response';
import { CookieUserService } from '../service-fitprenda/cookies/cookie-user.service';
import { CookieCredService } from '../service-fitprenda/cookies/cookie-cred.service';

@Component({
  selector: 'app-instore-marcas',
  templateUrl: './instore-marcas.component.html',
  styleUrls: ['./instore-marcas.component.css']
})
export class InstoreMarcasComponent implements OnInit {

  constructor(private router: Router
            , private fitprendaService: FitprendaService
            , private oCookieUser: CookieUserService
            , private oCookieCred: CookieCredService) { }

  public listEmpresas: FitprendaEmpresa[] | [];

  ngOnInit() {
    this.getEmpresas();
  }

  public onClickButton(key: string): void {
    try {
      if ((key !== undefined) && (key !== null)) {

        if (key !== this.oCookieCred.getToken()) {
          this.oCookieUser.flushCookie();
          this.oCookieCred.flushCookie();
        }

        this.router.navigate(['barcode/' + key]);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  private getEmpresas(): void {
    try {
      this.fitprendaService.postEmpresasFitPrenda().subscribe((oEmpresas: FitprendaResponse<FitprendaEmpresa[]>) => {
        if (oEmpresas.ResponseOK) {
          this.listEmpresas = oEmpresas.ResponseObject;
        }
      });
    } catch (ex) {
      console.log(ex);
    }
  }
}
