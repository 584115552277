<div class="container mt-2">
    <app-instore-header [IncludeNavigate]="false"></app-instore-header>

    <br>
  
    <div class="container-fitprenda">
      <div class="svg_central">
        <svg width="100%" height="100%" viewBox='0 0 512 512' fill='#c8d6eb' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'><g><polygon points='48,384 128,384 128,368 64,368 64,144 128,144 128,128 48,128 '/><polygon points='384,128 384,144 448,144 448,368 384,368 384,384 464,384 464,128 	'/><rect x='112' y='192' width='16' height='128'/><rect x='384' y='192' width='16' height='128'/><rect x='320' y='160' width='16' height='192'/><rect x='176' y='160' width='16' height='192'/><rect x='247' y='176' width='16' height='160'/></g></svg>
      </div>
  
      <div class="row justify-content-center">
          <div class="product_code">
            {{oCookieCred.getSKU()}}
          </div>
      </div>
  
  
      <div class="row justify-content-center">
          <div class="col text-center">
            <div class="title-fitprenda h4">{{oCookieCred.getDescripcionSKU()}}</div>
          </div>
      </div>
    </div>
    <br>
    <br>

    <div class="btn btn-dark btn-fitprenda-jch col-8 offset-2" (click)="onConocerMiTalle();">Conocer mi Talle</div>

    <a class="btn btn-block col-8 offset-2" (click)="onCancelar();">Cancelar</a>

</div>
