import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError, Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FitprendaAuthenticate } from 'src/app/models/response-fitprenda/fitprenda-authenticate';
import { FitprendaCalculate } from 'src/app/models/response-fitprenda/fitprenda-calculate';
import { FitprendaResponse } from 'src/app/models/response-fitprenda/fitprenda-response';
import { FitprendaEmpresa } from 'src/app/models/response-fitprenda/fitprenda-empresa';
import { FitprendaCustomerSettings } from 'src/app/models/response-fitprenda/fitprenda-customer-settings';

@Injectable({
  providedIn: 'root'
})
export class FitprendaService {
  private URLBASE: string = '';

  constructor(private httpClient: HttpClient) {
    this.URLBASE = environment.URL_BASE;
  }

  public postEmpresasFitPrenda(): Observable<FitprendaResponse<FitprendaEmpresa[]>> {
    const action: string = 'AUTHENTICATE';
    const controller: string = '/empresas';
    const headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

    try {

      return this.httpClient.post<FitprendaResponse<FitprendaEmpresa[]>>(this.URLBASE + controller, {headers})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

    } catch (ex) {
      console.log(ex);
    }
  }

  public postSettings(hash: string, sku: string): Observable<FitprendaResponse<FitprendaCustomerSettings>> {
    const action: string = 'AUTHENTICATE';
    const controller: string = '/empresa/settings';
    const headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

    try {
      const parametros = {
        token: hash,
        skuId: sku,
        verb: action
      };

      return this.httpClient.post<FitprendaResponse<FitprendaCustomerSettings>>(this.URLBASE + controller, JSON.stringify(parametros), {headers})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

    } catch (ex) {
      console.log(ex);
    }
  }

  public postAuthenticate(hash: string, sku: string): Observable<FitprendaResponse<FitprendaAuthenticate>> {
    const action: string = 'AUTHENTICATE';
    const controller: string = '/empresa/me';
    const headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

    try {
      const parametros = {
        token: hash,
        skuId: sku,
        verb: action
      };

      return this.httpClient.post<FitprendaResponse<FitprendaAuthenticate>>(this.URLBASE + controller, JSON.stringify(parametros), {headers})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

    } catch (ex) {
      console.log(ex);
    }
  }

  public postCalculate(sku: string, empresaId: number, genero: string, biotipo: string, altura: number, peso: number, busto: number, cadera: number, edad: number, plataformaId: number): Observable<FitprendaResponse<FitprendaCalculate>> {
    const action: string = 'CALCULATE';
    const controller: string = '/empresa/calculate';
    const headers: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

    try {
      const parametros = {
        skuId: sku,
        EmpresaID: empresaId,
        Genero: genero,
        Biotipo: biotipo,
        PersonaAltura: altura,
        PersonaPeso: peso,
        PersonaTallaBusto: busto,
        PersonaCadera: cadera,
        PersonaEdad: edad,
        platformId: plataformaId,
        verb: action
      };

      return this.httpClient.post<FitprendaResponse<FitprendaCalculate>>(this.URLBASE + controller, JSON.stringify(parametros), {headers})
      .pipe(
        retry(1),
        catchError(this.handleError)
      );

    } catch (ex) {
      console.log(ex);
    }
  }

  private handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // console.log(errorMessage);
    return throwError(errorMessage);
  }
}
