<div class="container mt-2">
    <app-instore-header [IncludeNavigate]="true" [IncludeNavigateOther]="false"></app-instore-header>

    <br>
    <div class="container-fitprenda">
      <div class="row justify-content-center">
        <div class="col text-center">
            <small><div class="subtitle-fitprenda">UPS !</div></small>
        </div>
    </div>

    <div class="svg_central">
      <svg width="100%" height="100%" viewBox='0 0 512 512' fill='#c8d6eb' xmlns='http://www.w3.org/2000/svg' xml:space='preserve'><g><path class="st0" d="M290.6,387.1h-69.2v-51.9h69.2V387.1z M281.9,300.6h-51.9l-8.6-155.6h69.2L281.9,300.6z"/></g><g><path class="st0" d="M184.9,65.3c6.9,10.8,14.9,18.9,24.1,24.9c12.7,8.1,28,12.3,47,12.6l0.1,0h0.1c19.2,0,34.7-4.1,47.3-12.4c9-5.9,16.6-14,23.6-25l133.4,40L438,167.4l-52.2-6.3L368,159l1,17.9l15.1,272.7H127.9L143,176.8l1-17.8l-17.7,2.1L74,167.2l-22.5-61.9L184.9,65.3 M320,47.5c-14,27.2-30.8,40.2-63.8,40.2c-32.5-0.5-50.5-13.2-64.2-40.2l-160,48l32,88l64-7.5l-16,288.5h288L384,176l64,7.8l32-88.2L320,47.5L320,47.5z"/></g></svg>
    </div>


    <div class="row justify-content-center">
        <div class="col text-center">
          <div class="title-fitprenda h4">No encontramos <br> este producto</div>
        </div>
    </div>
    </div>
    <br>
    <br>

    <div class="btn btn-dark btn-fitprenda-jch col-8 offset-2" (click)="onClickButton();">Escanear Otro</div>

</div>
