import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InstoreMarcasComponent } from './instore-marcas/instore-marcas.component';
import { InstoreBarcodeComponent } from './instore-barcode/instore-barcode.component';
import { ProductoMaleComponent } from './producto-male/producto-male.component';
import { ProductoFemaleComponent } from './producto-female/producto-female.component';
import { InstoreComponent } from './instore/instore.component';
import { InstoreWelcomeComponent } from './instore-welcome/instore-welcome.component';
import { InstoreProductoComponent } from './instore-producto/instore-producto.component';
import { InstoreNoEncontradoComponent } from './instore-noencontrado/instore-noencontrado.component';

const routes: Routes = [
  {path: '', component: InstoreWelcomeComponent},
  {path: 'id/:hash', component: InstoreWelcomeComponent},
  {path: 'instore/marcas', component: InstoreMarcasComponent},
  {path: 'barcode/:hash', component: InstoreBarcodeComponent},
  {path: 'instore/producto', component: InstoreProductoComponent},
  {path: 'instore/noencontrado', component: InstoreNoEncontradoComponent},
  {path: 'instore/male', component: ProductoMaleComponent},
  {path: 'instore/female', component: ProductoFemaleComponent},
  {path: 'instore/details', component: InstoreComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
