export class CookieUser {
    public Altura: number | undefined;
    public Busto: number | undefined;
    public Peso: number | undefined;
    public Cadera: number | undefined;
    public Edad: number | undefined;
    public Genero: string | undefined;
    public Biotipo: number | undefined;
    public Items: CookieUserItem[] | undefined;

    constructor() {}

    public AddItem(code: string, description: string, genre: string, position: number, size: string, predictionId: number): void {
        try {
            if ((this.Items === undefined) || (this.Items === null)) {
                this.Items = new Array<CookieUserItem>();
            }

            this.Items.push({
                SKU: code
                , Descripcion: description
                , Genero: genre
                , Posicion: position
                , Talle: size
                , PrediccionID: predictionId
            });

        } catch (ex) {
            console.log(ex);
        }
    }
}

export class CookieUserItem {
    public SKU: string | undefined;
    public Descripcion: string | undefined;
    public Genero: string | undefined;
    public Posicion: number | undefined;
    public Talle: string | undefined;
    public PrediccionID: number | undefined;

    constructor() {}
}
