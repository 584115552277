import { Injectable } from '@angular/core';
import { CookieUser, CookieUserItem } from 'src/app/models/cookie/cookie-user';
import { StringMapWithRename } from '@angular/compiler/src/compiler_facade_interface';

@Injectable({
  providedIn: 'root'
})
export class CookieUserService {
  private oCookieUser: CookieUser;
  private CookieName: string;

  constructor() {
    this.CookieName = 'FitPrendaUser';

    if (this.isExists()) {
      this.loadCookie();
    } else {
      this.oCookieUser = new CookieUser();
    }
  }

  public setAltura(value: number): void {
    try {
      this.oCookieUser.Altura = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getAltura(): number {
    return this.oCookieUser.Altura;
  }

  public setBusto(value: number): void {
    try {
      this.oCookieUser.Busto = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getBusto(): number {
    return this.oCookieUser.Busto;
  }

  public setPeso(value: number): void {
    try {
      this.oCookieUser.Peso = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getPeso(): number {
    return this.oCookieUser.Peso;
  }

  public setCadera(value: number): void {
    try {
      this.oCookieUser.Cadera = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getCadera(): number {
    return this.oCookieUser.Cadera;
  }

  public setEdad(value: number): void {
    try {
      this.oCookieUser.Edad = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getEdad(): number {
    return this.oCookieUser.Edad;
  }

  public setGenero(value: string): void {
    try {
      this.oCookieUser.Genero = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getGenero(): string {
    return this.oCookieUser.Genero;
  }

  public setBiotipo(value: number): void {
    try {
      this.oCookieUser.Biotipo = value;
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }
  public getBiotipo(): number {
    return this.oCookieUser.Biotipo;
  }

  public getItems(): CookieUserItem[] {
    return this.oCookieUser.Items;
  }

  public AddElement(SKU: string, Descripcion: string, Genero: string, Posicion: number, Talle: string, Prediccion: number): void {
    try {
      this.oCookieUser.AddItem(SKU, Descripcion, Genero, Posicion, Talle, Prediccion);
      this.saveCookie();
    } catch (ex) {
      console.log(ex);
    }
  }

  public LastGenero(): string {
    let genero: string = '';

    try {
      if ((this.oCookieUser.Items !== undefined) && (this.oCookieUser.Items !== null) && (this.oCookieUser.Items.length > 0)) {
        genero = this.oCookieUser.Items[this.oCookieUser.Items.length - 1].Genero;
      }
    } catch (ex) {
      console.log(ex);
    }

    return genero;
  }

  public LastPosicion(): number {
    let posicion: number = 0;

    try {
      if ((this.oCookieUser.Items !== undefined) && (this.oCookieUser.Items !== null) && (this.oCookieUser.Items.length > 0)) {
        posicion = this.oCookieUser.Items[this.oCookieUser.Items.length - 1].Posicion;
      }
    } catch (ex) {
      console.log(ex);
    }

    return posicion;
  }

  public flushCookie(): void {
    try {
      if (this.isExists()) {
        sessionStorage.removeItem(this.CookieName);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  private isExists(): boolean {
    let existsCookie: boolean = false;

    try {
      if (sessionStorage.getItem(this.CookieName)) {
        existsCookie = true;
      }
    } catch (ex) {
      console.log(ex);
    }

    return existsCookie;
  }

  private loadCookie(): void {
    let oCookie: CookieUser;

    try {
      oCookie = JSON.parse(atob(sessionStorage.getItem(this.CookieName)));

      this.oCookieUser = new CookieUser();

      this.oCookieUser.Altura = oCookie.Altura;
      this.oCookieUser.Biotipo = oCookie.Biotipo;
      this.oCookieUser.Busto = oCookie.Busto;
      this.oCookieUser.Cadera = oCookie.Cadera;
      this.oCookieUser.Edad = oCookie.Edad;
      this.oCookieUser.Peso = oCookie.Peso;
      this.oCookieUser.Items = oCookie.Items;

    } catch (ex) {
      console.log(ex);
    }
  }

  private saveCookie(): void {
    try {
      sessionStorage.setItem(this.CookieName, btoa(JSON.stringify(this.oCookieUser)));
    } catch (ex) {
      console.log(ex);
    }
  }
}
