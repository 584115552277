<div class="container mt-2">

    <app-instore-header [IncludeNavigate]="false"></app-instore-header>

    <div class="container-fitprenda">

        <div class="row justify-content-center">
            <div class="col text-center">
                <div class="title-fitprenda"><b>Escaneá<br>el código de barras</b></div>
                <small><div class="subtitle-fitprenda">de la etiqueta del producto</div></small>
            </div>
        </div>
    
        <br>
        <div class="icon_barcode" [ngClass]="{'d-none': startedScan}"></div>
        <br>
    
        <div class="row justify-content-center no-gutters" [ngClass]="{'d-none': !startedScan}">
            <div class="mx-0 text-center">
    
                <div id="inputBarcode">
                    <div id="interactive" class="viewport"></div>
                </div>
            </div>
        </div>

    </div>

    <div class="btn btn-fitprenda-jch btn-block col-8 offset-2" (click)="gotoProduct()">{{actionScan}}</div>

    <div class="row justify-content-center mt-1">
        <div class="col col-md-6 link-sizes">
            <a type="button" class="btn btn-block col-8 offset-2" (click)="onInstoreDetails();">
                VER TALLES CONSULTADOS
            </a>
        </div>
    </div>
</div>
