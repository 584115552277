import { Injectable } from '@angular/core';
import { CookieCred } from 'src/app/models/cookie/cookie-cred';

@Injectable({
  providedIn: 'root'
})
export class CookieCredService {
  private oCookieCred: CookieCred;
  private CookieName: string;

  constructor() {
    this.CookieName = 'FitPrendaCred';

    if (this.isExists()) {
      this.loadCookie();
    } else {
      this.oCookieCred = new CookieCred();
    }
  }

  public setToken(value: string): void {
    this.oCookieCred.Token = value;
    this.saveCookie();
  }

  public getToken(): string {
    return this.oCookieCred.Token;
  }

  public setEmpresa(value: number): void {
    this.oCookieCred.EmpresaID = value;
    this.saveCookie();
  }

  public getEmpresa(): number {
    return this.oCookieCred.EmpresaID;
  }

  public setEmpresaNombre(value: string): void {
    this.oCookieCred.Empresa = value;
    this.saveCookie();
  }

  public getEmpresaNombre(): string {
    return this.oCookieCred.Empresa;
  }

  public setSKU(value: string): void {
    this.oCookieCred.SKU = value;
    this.saveCookie();
  }

  public getSKU(): string {
    return this.oCookieCred.SKU;
  }

  public setDescripcionSKU(value: string): void {
    this.oCookieCred.Descripcion = value;
    this.saveCookie();
  }

  public getDescripcionSKU(): string {
    return this.oCookieCred.Descripcion;
  }

  public setImagenSKU(value: string): void {
    this.oCookieCred.Imagen = value;
    this.saveCookie();
  }

  public getImagenSKU(): string {
    return this.oCookieCred.Imagen;
  }

  public setGenero(value: string): void {
    this.oCookieCred.Genero = value;
    this.saveCookie();
  }

  public getGenero(): string {
    return this.oCookieCred.Genero;
  }

  public setPosicion(value: number): void {
    this.oCookieCred.Posicion = value;
    this.saveCookie();
  }

  public getPosicion(): number {
    return this.oCookieCred.Posicion;
  }

  public setPlataforma(value: number): void {
    this.oCookieCred.PlataformID = value;
    this.saveCookie();
  }

  public getPlataform(): number {
    return this.oCookieCred.PlataformID;
  }

  public flushCookie(): void {
    try {
      if (this.isExists()) {
        sessionStorage.removeItem(this.CookieName);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  private isExists(): boolean {
    let existsCookie: boolean = false;

    try {
      if (sessionStorage.getItem(this.CookieName)) {
        existsCookie = true;
      }
    } catch (ex) {
      console.log(ex);
    }

    return existsCookie;
  }

  private loadCookie(): void {
    try {
      this.oCookieCred = JSON.parse(atob(sessionStorage.getItem(this.CookieName)));
    } catch (ex) {
      console.log(ex);
    }
  }

  private saveCookie(): void {
    try {
      sessionStorage.setItem(this.CookieName, btoa(JSON.stringify(this.oCookieCred)));
    } catch (ex) {
      console.log(ex);
    }
  }
}
