import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-instore-welcome',
  templateUrl: './instore-welcome.component.html',
  styleUrls: ['./instore-welcome.component.css']
})
export class InstoreWelcomeComponent implements OnInit {
  private key: string;

  constructor(private router: Router
            , private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.params.hash) {
      this.key = this.activatedRoute.snapshot.params.hash;
    }

    window.scrollTo(0, document.body.scrollHeight);
  }

  public onClickButton(): void {
    try {
        if ((this.key !== undefined) && (this.key !== null) && (this.key !== '')) {
          this.router.navigate(['barcode/' + this.key]);
        } else {
          this.router.navigate(['instore/marcas']);
        }
    } catch (ex) {
      console.log(ex);
    }
  }
}
