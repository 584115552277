export class CookieCred {
    public Token: string | undefined;
    public EmpresaID: number | undefined;
    public Empresa: string | undefined;
    public SKU: string | undefined;
    public Descripcion: string | undefined;
    public Imagen: string | undefined;
    public Genero: string | undefined;
    public Posicion: number | undefined;
    public PlataformID: number | undefined;

    constructor() {}
}
