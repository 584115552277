import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InstoreComponent } from './instore/instore.component';
import { InstoreMarcasComponent } from './instore-marcas/instore-marcas.component';
import { InstoreBarcodeComponent } from './instore-barcode/instore-barcode.component';
import { InstoreProductoComponent } from './instore-producto/instore-producto.component';
import { ProductoFemaleComponent } from './producto-female/producto-female.component';
import { ProductoMaleComponent } from './producto-male/producto-male.component';
import { InstoreNoEncontradoComponent } from './instore-noencontrado/instore-noencontrado.component';
import { InstoreWelcomeComponent } from './instore-welcome/instore-welcome.component';
import { InstoreFooterComponent } from './instore-footer/instore-footer.component';
import { InstoreHeaderComponent } from './instore-header/instore-header.component';

@NgModule({
  declarations: [
    AppComponent,
    InstoreComponent,
    InstoreMarcasComponent,
    InstoreBarcodeComponent,
    InstoreProductoComponent,
    ProductoFemaleComponent,
    ProductoMaleComponent,
    InstoreNoEncontradoComponent,
    InstoreWelcomeComponent,
    InstoreFooterComponent,
    InstoreHeaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
