import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieCredService } from '../service-fitprenda/cookies/cookie-cred.service';

@Component({
  selector: 'app-instore-producto',
  templateUrl: './instore-producto.component.html',
  styleUrls: ['./instore-producto.component.css']
})
export class InstoreProductoComponent implements OnInit {

  constructor(private router: Router
            , public oCookieCred: CookieCredService) { }

  ngOnInit() {
  }

  public onConocerMiTalle() {
    try {
      if (this.oCookieCred.getGenero() === 'MASCULINO') {
        this.router.navigate(['instore/male']);
      } else if (this.oCookieCred.getGenero() === 'FEMENINO') {
        this.router.navigate(['instore/female']);
      } else {
        this.router.navigate(['instore/noencontrado']);
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  public onCancelar() {
    try {
      this.router.navigate(['barcode/' + this.oCookieCred.getToken()]);
    } catch (ex) {
      console.log(ex);
    }
  }
}
